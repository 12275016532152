import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="reservations-link"
export default class extends Controller {
  async copy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.element.dataset.checkinUrl);

    await get('/flash', {
      query: {
        title: 'Copied!',
        body: 'Check-in link copied to clipboard',
        type: 'success'
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream'
    })
  }
}
